import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Banner = () => {
  return (
    <BannerContainer>
      <BannerContent>
        <StaticImage
          className="banner-image"
          src="../assets/images/banner/students.png"
          alt="10 años preparandote academicamente"
          layout="fixed"
          placeholder="blurred"
          height={180}
        />
        <BannerText>
          Mas de <strong>10 años</strong> preparando
          <br />a miles de alumnos
        </BannerText>
        <StaticImage
          src="../assets/images/banner/badge.png"
          alt="10 años preparandote academicamente"
          layout="fixed"
          placeholder="blurred"
          height={100}
        />
      </BannerContent>
    </BannerContainer>
  )
}

export default Banner

const BannerContainer = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 110px;
`

const BannerText = styled.h4`
  color: #e9dd50;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 45px;
  position: relative;
  text-align: right;
  padding-right: 40px;
  margin-right: 40px;

  strong {
    font-weight: bolder;
    font-style: normal;
  }

  &:after {
    content: "";
    width: 1px;
    background-color: #e9dd50;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
`

const BannerContent = styled.div`
  background-color: #060f52;
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 35px;
  position: relative;

  @media screen and (min-width: 1200px) {
    border-radius: 100px;
  }

  .banner-image {
    position: absolute;
    left: 100px;
    bottom: 0;
  }
`
