import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Categories from "../components/categories"
import Banner from "../components/banner"
import Advantages from "../components/advantages"
import Contact from "../components/contact"
import Footer from "../components/footer"

const IndexPage = () => (
  <Layout>
    <Seo title="Inicio" />
    <Hero
      title="Preparate con los mejores"
      subtitle="¡Inscríbete Hoy!"
      type="HOME"
      isHome
    />
    <Categories />
    <Banner />
    <Advantages />
    <Contact />
    <Footer displayImage />
  </Layout>
)

export default IndexPage
