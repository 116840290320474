import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { ButtonLink } from "./shared/button"

const Categories = () => {
  return (
    <CategoriesContainer id="oferta-educativa">
      <CategoriesContent>
        <PreparatoriaContainer>
          <h4>Preparatoria</h4>
          <CategoryCard>
            <StaticImage
              className="category-image"
              src="../assets/images/hero/background/preparatoria.jpg"
              alt="Preparatoria"
              layout="constrained"
              placeholder="blurred"
            />
            <CategoryOverlay>
              <h5>Preparatoria</h5>
              <p>
                Te ofrecemos un plan de estudios enfocado a la guía de Pearson,
                de esta manera asegurar la mejor preparación en conocimiento,
                con nuestros maestros y nuestro material tendrás todo a tu
                alcance para pasar. ¡Logra tu pase!
              </p>
              <ButtonLink to="/preparatoria/">Mas información</ButtonLink>
            </CategoryOverlay>
          </CategoryCard>
        </PreparatoriaContainer>
        <FacultadContainer>
          <h4>Facultad</h4>
          <CategoryCard>
            <StaticImage
              className="category-image"
              src="../assets/images/hero/background/facultad.jpg"
              alt="Preparatoria"
              layout="constrained"
              placeholder="blurred"
            />
            <CategoryOverlay>
              <h5>Facultad</h5>
              <p>
                Te queremos acompañar durante una etapa tan importante como lo
                es prepararte para entrar a la universidad, durante el curso de
                asesorías vamos a cubrir las materias básicas y las materias
                especializadas por carrera que vienen la guía de Pearson. ¡Logra
                tu meta!
              </p>
              <ButtonLink to="/facultad/">Mas información</ButtonLink>
            </CategoryOverlay>
          </CategoryCard>
        </FacultadContainer>
      </CategoriesContent>
    </CategoriesContainer>
  )
}

export default Categories

const CategoriesContainer = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 85px;
  margin-bottom: 85px;
`

const CategoriesContent = styled.div`
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  padding: 0 35px;

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }

  .category-image {
    height: 100%;
  }

  h4 {
    color: #060f52;
    font-size: 40px;
    font-family: "Montserrat";
    font-weight: bold;
    font-style: normal;
    margin-bottom: 20px;
  }
`

const PreparatoriaContainer = styled.div`
  overflow: hidden;
`

const FacultadContainer = styled.div`
  overflow: hidden;
`

const CategoryCard = styled.div`
  height: 500px;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  transition: 1s all ease-in-out;

  &:hover > div {
    display: flex;
  }
`

const CategoryOverlay = styled.div`
  display: none;
  background-color: #021e7ba3;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  padding: 0 30%;

  h5 {
    font-size: 30px;
    font-weight: normal;
  }

  p {
    font-size: 16px;
    font-weight: normal;
    padding: 0 25px;
  }
`
