import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import styled from "styled-components"

import Advantage1 from "../assets/images/advantages/advantage-1.svg"
import Advantage2 from "../assets/images/advantages/advantage-2.svg"
import Advantage3 from "../assets/images/advantages/advantage-3.svg"
import Advantage4 from "../assets/images/advantages/advantage-4.svg"
import Advantage5 from "../assets/images/advantages/advantage-5.svg"
import Advantage6 from "../assets/images/advantages/advantage-6.svg"
import Advantage7 from "../assets/images/advantages/advantage-7.svg"
import Advantage8 from "../assets/images/advantages/advantage-8.svg"
import Advantage9 from "../assets/images/advantages/advantage-9.svg"
import Advantage10 from "../assets/images/advantages/advantage-10.svg"

const Advantages = () => {
  const data = useStaticQuery(graphql`
    query AdvantagesQuery {
      allAdvantagesJson(sort: { fields: position }) {
        edges {
          node {
            id
            title
            position
          }
        }
      }
    }
  `)

  function getIcon({ position, title }) {
    switch (position) {
      case 1:
        return <img src={Advantage1} alt={title} />
      case 2:
        return <img src={Advantage2} alt={title} />
      case 3:
        return <img src={Advantage3} alt={title} />
      case 4:
        return <img src={Advantage4} alt={title} />
      case 5:
        return <img src={Advantage5} alt={title} />
      case 6:
        return <img src={Advantage6} alt={title} />
      case 7:
        return <img src={Advantage7} alt={title} />
      case 8:
        return <img src={Advantage8} alt={title} />
      case 9:
        return <img src={Advantage9} alt={title} />
      case 10:
        return <img src={Advantage10} alt={title} />
      default:
        return null
    }
  }

  return (
    <AdvantagesContainer id="advantages">
      <AdvantagesContent>
        <h3>Ventajas de estudiar con nosotros:</h3>
        <AdvantagesCards>
          {data.allAdvantagesJson.edges.map(({ node }) => (
            <AdvantageCard key={node.id}>
              {getIcon(node)}
              <label>{node.title}</label>
            </AdvantageCard>
          ))}
        </AdvantagesCards>
      </AdvantagesContent>
    </AdvantagesContainer>
  )
}

export default Advantages

const AdvantagesContainer = styled.section`
  display: flex;
  justify-content: center;
`

const AdvantagesContent = styled.div`
  max-width: 1200px;
  width: 100%;
  margin-bottom: 100px;
  padding: 0 35px;

  @media screen and (min-width: 1200px) {
    padding: 0;
  }

  h3 {
    color: #060f52;
    font-size: 50px;
    font-family: "Montserrat";
    font-weight: bold;
    font-style: normal;
    margin-bottom: 20px;
  }
`

const AdvantagesCards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }
`

const AdvantageCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 80px;
  }

  label {
    color: #060f52;
    font-family: "Montserrat";
    font-size: 22px;
    text-align: center;
    display: block;
    margin-top: 10px;
  }
`
