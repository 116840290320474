import axios from "axios"
import React, { useState } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { MdOutlineLocationOn } from "react-icons/md"
import {
  AiOutlinePhone,
  AiOutlineMail,
  AiOutlineGlobal,
  AiOutlineFacebook,
} from "react-icons/ai"

import { Button } from "./shared/button"

const Contact = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [emailStatus, setEmailStatus] = useState("")

  function handleSubmit(e) {
    e.preventDefault()

    const formData = new FormData()
    formData.append("name", name)
    formData.append("email", email)
    formData.append("message", message)

    axios
      .post("email.php", formData)
      .then(res => {
        setEmailStatus(
          res.data
            ? "Tu mensaje fue enviado exitosamente. Nos pondremos en contacto contigo muy pronto."
            : "Algo salio mal. Por favor intenta de nuevo mas tarde."
        )

        if (res.data) {
          setName("")
          setEmail("")
          setMessage("")
        }
      })
      .catch(e => {
        setEmailStatus("Something went wrong. Please try again later.")
      })
  }

  function handleNameChange(e) {
    setName(e.target.value)
  }

  function handleEmailChange(e) {
    setEmail(e.target.value)
  }

  function handleMessageChange(e) {
    setMessage(e.target.value)
  }

  return (
    <EmailContainer id="contacto">
      <EmailContent>
        <LeftContent>
          <StaticImage
            src="../assets/images/logo.png"
            alt="Asesorias CAMI"
            layout="fixed"
            placeholder="tracedSVG"
            height={50}
          />
          <p>
            Queremos que logres tus objetivos paso a paso, cuéntanos tus dudas y
            déjanos guiarte al éxito.
          </p>
          <ContactInfo>
            <label>Asesorias CAMI - Contacto</label>
            <ul>
              <li>
                <MdOutlineLocationOn
                  css={`
                    font-size: 1.5rem;
                  `}
                />
                <span>
                  Av Las Palmas 118, Paseo de Las Palmas III, 66635 Apodaca,
                  N.L.
                </span>
              </li>
              <li>
                <AiOutlinePhone
                  css={`
                    font-size: 1.25rem;
                  `}
                />
                <span>
                  T. <a href="tel:8117469882">(81)1746.9882</a>{" "}
                </span>
              </li>
              <li>
                <AiOutlineMail
                  css={`
                    font-size: 1.25rem;
                  `}
                />
                <span>
                  E.{" "}
                  <a href="mailto:asesoriascami@gmail.com">
                    asesoriascami@gmail.com
                  </a>
                </span>
              </li>
              <li>
                <AiOutlineGlobal
                  css={`
                    font-size: 1.25rem;
                  `}
                />
                <span>W. https://www.asesoriascami.com</span>
              </li>
            </ul>
            <SocialLink
              href="https://www.facebook.com/Asesorias-CAMI-1296635490464865"
              target="_blank"
            >
              <AiOutlineFacebook
                css={`
                  font-size: 1.5rem;
                `}
              />
            </SocialLink>
          </ContactInfo>
        </LeftContent>
        <RightContent>
          <form action="#" onSubmit={handleSubmit}>
            <FormWrap>
              <label>
                Nombre:
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={handleNameChange}
                  required
                />
              </label>
              <label>
                E-Mail:
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </label>
              <label>
                Mensaje:
                <textarea
                  name="message"
                  id="message"
                  rows="5"
                  value={message}
                  onChange={handleMessageChange}
                  required
                />
              </label>
              <Button type="submit">Enviar</Button>
              {emailStatus !== "" ? (
                <EmailMessage>{emailStatus}</EmailMessage>
              ) : null}
            </FormWrap>
          </form>
        </RightContent>
      </EmailContent>
    </EmailContainer>
  )
}

export default Contact

const EmailContainer = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
`

const EmailContent = styled.div`
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr;
  padding: 60px;
  grid-gap: 3.5rem;
  background-color: #1a2050;

  @media screen and (min-width: 968px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 120px;
  }

  @media screen and (min-width: 1200px) {
    border-radius: 60px;
  }
`

const LeftContent = styled.div`
  p {
    margin-top: 20px;
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
  }
`

const ContactInfo = styled.div`
  margin-top: 100px;

  label {
    font-size: 1rem;
    font-weight: 500;
    font-style: normal;
    display: block;
    margin-bottom: 15px;
  }

  ul {
    list-style-type: none;
    margin-bottom: 20px;

    li {
      display: flex;
      margin-bottom: 5px;

      span {
        margin-left: 10px;
      }

      a {
        color: white;
      }
    }
  }
`

const RightContent = styled.div``

const SocialLink = styled.a`
  color: white;

  &:hover {
    color: #ccc;
  }
`

const FormWrap = styled.div`
  background-color: white;
  border-radius: 40px;
  padding: 50px;

  label {
    color: #15294c;
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
  }

  input {
    height: 40px;
  }

  input,
  textarea {
    border-radius: 12px;
    background-color: #f2f2f2;
    resize: none;
    outline: none;
    border: none;
    padding: 1rem 0.5rem;
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
`

const EmailMessage = styled.span`
  display: block;
  margin-top: 10px;
  color: #15294c;
`
